import React from 'react';
import Layout from '../components/layout';

const References = () => {
  return (
    <Layout className="pt-6">
      <section className="section pt-6 margin-6">
        <div className="container">
          <div className="content" style={{ wordBreak: 'break-word' }}>
            <h3 className="is-size-3 is-uppercase">References</h3>
            <ul className="is-size-6-mobile">
              <li>
                <sup>1 </sup>
                Kelly, A. B., Evans-Whipp, T. J., Smith, R., Chan, G. C. K.,
                Toumbourou, J. W., Patton, G. C., Hemphill, S. A., Hall, W. D.
                and Catalano, R. F. (2015), A longitudinal study of the
                association of adolescent polydrug use, alcohol use and high
                school non-completion. Addiction, 110, 627– 635. doi:
                10.1111/add.12829.
              </li>
              <li>
                <sup>2 </sup>
                Hemphill, S. A., Kotevski, A., Tollit, M., Smith, R.,
                Herrenkohl, T. I., Toumbourou, J. W., & Catalano, R. F. (2012).
                Longitudinal predictors of cyber and traditional bullying
                perpetration in Australian secondary school students. The
                Journal of adolescent health : official publication of the
                Society for Adolescent Medicine, 51(1), 59–65.{' '}
                <a
                  className="link"
                  href="https://doi.org/10.1016/j.jadohealth.2011.11.019"
                  target="blank"
                  rel="noreferrer"
                >
                  https://doi.org/10.1016/j.jadohealth.2011.11.019
                </a>
              </li>
              <li>
                <sup>3 </sup>Carnevale, A. P., Rose, S. J., &amp; Cheah, B. C.
                (2013). The college payoff: education, occupations, lifetime
                earnings. Georgetown University, Center on Education and
                Workforce.
              </li>
              <li>
                <sup>4 </sup>Jones, E. P., Margolius, M., Rollock, M., Yan, C.
                T., Cole, M. L., Zaff, J. F., “Disciplined and Disconnected: How
                Students Experience Exclusionary Discipline in Minnesota and the
                Promise of Non-Exclusionary Alternatives,” America&#39;s Promise
                Alliance (2018).
              </li>
              <li>
                <sup>5 </sup>Mendez, L. &amp; Knoff, H., “Who gets suspended
                from school and why: A demographic analysis of schools and
                disciplinary infractions in a large school district,” Education
                and Treatment of Children. 26. 30-51(2003).
              </li>
              <li>
                <sup>6 </sup>
                Nelson, B. B., Dudovitz, R. N., Coker, T. R., Barnert, E. S.,
                Biely, C., Li, N., Szilagyi, P. G., Larson, K., Halfon, N.,
                Zimmerman, F. J., &amp; Chung, P. J. (2016). Predictors of Poor
                School Readiness in Children Without Developmental Delay at Age
                2. Pediatrics, 138(2), e20154477.{' '}
                <a
                  className="link"
                  href="https://doi.org/10.1542/peds.2015-4477"
                  target="blank"
                  rel="noreferrer"
                >
                  https://doi.org/10.1542/peds.2015-4477
                </a>
              </li>
              <li>
                <sup>7 </sup>
                Arcia, E. (2006). Achievement and Enrollment Status of Suspended
                Students: Outcomes in a Large, Multicultural School District.
                Education and Urban Society, 38(3), 359–369.{' '}
                <a
                  className="link"
                  href="https://doi.org/10.1177/0013124506286947"
                  target="blank"
                  rel="noreferrer"
                >
                  https://doi.org/10.1177/0013124506286947
                </a>
              </li>
              <li>
                <sup>8 </sup>
                Noltemeyer, A. L., Ward, R. M., &amp; Mcloughlin, C. (2015).
                Relationship Between School Suspension and Student Outcomes: A
                Meta-Analysis. School Psychology Review, 44(2), 224–240.{' '}
                <a
                  className="link"
                  href="https://doi.org/10.17105/spr-14-0008.1"
                  target="blank"
                  rel="noreferrer"
                >
                  https://doi.org/10.17105/spr-14-0008.1
                </a>
              </li>
              <li>
                <sup>9 </sup>
                Jones, E., Margolius, M., Rollock, M., Yan, C., Colem, M., &amp;
                Zaff, J. (2018). Disciplined and disconnected: the experience of
                exclusionary discipline in Minnesota and the promise of
                non-exclusionary alternatives. Retrieved from{' '}
                <a
                  className="link"
                  href="http://gradnation.americaspromise.org/report/disciplined-and-disconnected"
                  target="blank"
                  rel="noreferrer"
                >
                  http://gradnation.americaspromise.org/report/disciplined-and-disconnected
                </a>
              </li>
              <li>
                <sup>10 </sup>
                Levy, S., Campbell, M. C., Shea, C. L., DuPont, C. M., & DuPont,
                R. L. (2020). Trends in substance nonuse by high school seniors:
                1975–2018. Pediatrics, e2020007187. Doi:
                10.1542/peds.2020-007187
              </li>
              <li>
                <sup>11 </sup>
                NIDA. 2021, March 15. Brain and Addiction. Retrieved from{' '}
                <a
                  className="link"
                  href="https://teens.drugabuse.gov/drug-facts/brain-and-addiction"
                  target="blank"
                  rel="noreferrer"
                >
                  https://teens.drugabuse.gov/drug-facts/brain-and-addiction
                </a>{' '}
                on 2021, August 27
              </li>
              <li>
                <sup>12 </sup>
                NIDA. 2021, July 27. Drugs and the Brain. Retrieved from{' '}
                <a
                  className="link"
                  href="https://www.drugabuse.gov/publications/drugs-brains-behavior-science-addiction/drugs-brain"
                  target="blank"
                  rel="noreferrer"
                >
                  https://www.drugabuse.gov/publications/drugs-brains-behavior-science-addiction/drugs-brain
                </a>{' '}
                on 2021, August 27
              </li>
              <li>
                <sup>13 </sup>
                U.S. Department of Health and Human Services. (n.d.). Alcohol
                consumption and the risk of cancer. National Institute on
                Alcohol Abuse and Alcoholism.{' '}
                <a
                  className="link"
                  href="https://pubs.niaaa.nih.gov/publications/arh25-4/263-270.htm."
                  target="blank"
                  rel="noreferrer"
                >
                  https://pubs.niaaa.nih.gov/publications/arh25-4/263-270.htm.
                </a>{' '}
                on 2021, August 27
              </li>
              <li>
                <sup>14 </sup>
                NIDA. 2021. Marijuana Potency. Retrieved from:{' '}
                <a
                  className="link"
                  href="https://www.drugabuse.gov/drug-topics/marijuana/marijuana-potency"
                  target="blank"
                  rel="noreferrer"
                >
                  https://www.drugabuse.gov/drug-topics/marijuana/marijuana-potency
                </a>
                , August 27
              </li>
              <li>
                <sup>15 </sup>
                NIDA. 2019, October 18. How To Recognize a Substance Use
                Disorder. Retrieved from{' '}
                <a
                  className="link"
                  href="https://www.drugabuse.gov/publications/step-by-step-guides-to-finding-treatment-drug-use-disorders/if-your-teen-or-young-adult-has-problem-drugs/how-to-recognize-substance-use"
                  target="blank"
                  rel="noreferrer"
                >
                  https://www.drugabuse.gov/publications/step-by-step-guides-to-finding-treatment-drug-use-disorders/if-your-teen-or-young-adult-has-problem-drugs/how-to-recognize-substance-use
                </a>{' '}
                on 2021, August 27
              </li>
              <li>
                <sup>16 </sup>
                Jackler, R., Chau, C., Getachew, B., Whitcomb, M.,
                Lee-Heidenreich, J., Bhatt, A., Kim-O’Sullivan, S., Hoffman, Z.,
                Jackler, L., & Ramamurthi, D. (2019). JUUL Advertising Over its
                First Three Years on the Market. Stanford School of Medicine.{' '}
                <a
                  className="link"
                  href="https://tobacco-img.stanford.edu/wp-content/uploads/2021/07/21231836/JUUL_Marketing_Stanford.pdf"
                  target="blank"
                  rel="noreferrer"
                >
                  https://tobacco-img.stanford.edu/wp-content/uploads/2021/07/21231836/JUUL_Marketing_Stanford.pdf{' '}
                </a>{' '}
              </li>
              <li>
                <sup>17 </sup>
                CDC. (2017, March 23). E-cigarette Ads and Youth. Centers for
                Disease Control and Prevention.{' '}
                <a
                  className="link"
                  href="https://www.cdc.gov/vitalsigns/ecigarette-ads/index.html"
                  target="blank"
                  rel="noreferrer"
                >
                  https://www.cdc.gov/vitalsigns/ecigarette-ads/index.html{' '}
                </a>{' '}
              </li>
              <li>
                <sup>18 </sup>
                NIDA. 2021, July 27. Preventing Drug Use Among Children and
                Adolescents. A Research-Based Guide for Parents, Educators, and
                Community Leaders Retrieved from{' '}
                <a
                  className="link"
                  href="https://www.drugabuse.gov/sites/default/files/preventingdruguse_2.pdf"
                  target="blank"
                  rel="noreferrer"
                >
                  https://www.drugabuse.gov/sites/default/files/preventingdruguse_2.pdf{' '}
                </a>
                on 2021, August 27
              </li>
              <li>
                <sup>19 </sup>
                Hoekstra, P. J. (2020). Suicidality in children and adolescents:
                lessons to be learned from the COVID-19 crisis. European Child &
                Adolescent Psychiatry, 29(6), 737–738.{' '}
                <a
                  className="link"
                  href="https://doi.org/10.1007/s00787-020-01570-z"
                  target="blank"
                  rel="noreferrer"
                >
                  https://doi.org/10.1007/s00787-020-01570-z
                </a>{' '}
                on 2021, August 27
              </li>
              <li>
                <sup>20 </sup>
                Ali, S., Mouton, C. P., Jabeen, S., Ofoemezie, E. K., Bailey, R.
                K., Shahid, M., & Zeng, Q. (2011). Early detection of illicit
                drug use in teenagers. Innovations in clinical neuroscience,
                8(12), 24–28.
              </li>

              <li>
                <sup>21 </sup>
                NIDA. 2021, April 13. Part 1: The Connection Between Substance
                Use Disorders and Mental Illness. Retrieved from{' '}
                <a
                  className="link"
                  href="https://www.drugabuse.gov/publications/research-reports/common-comorbidities-substance-use-disorders/part-1-connection-between-substance-use-disorders-mental-illness"
                  target="blank"
                  rel="noreferrer"
                >
                  https://www.drugabuse.gov/publications/research-reports/common-comorbidities-substance-use-disorders/part-1-connection-between-substance-use-disorders-mental-illness{' '}
                </a>
                on 2021, August 27
              </li>
              <li>
                <sup>22 </sup>
                Mountainside. 2019, August 9. Addiction 101: What It Is and How
                to Talk About It. Retrieved from{' '}
                <a
                  className="link"
                  href="https://mountainside.com/blog/drug-addiction/addiction-101-what-it-is-and-how-to-talk-about-it"
                  target="blank"
                  rel="noreferrer"
                >
                  https://mountainside.com/blog/drug-addiction/addiction-101-what-it-is-and-how-to-talk-about-it
                </a>{' '}
                on 2021, August 27
              </li>
              <li>
                <sup>23 </sup>
                Every Student Succeeds Act, 20 U.S.C. Section 6301 (2015).{' '}
                <a
                  className="link"
                  href="https://www.congress.gov/bill/114th-congress/senate-bill/1177"
                  target="blank"
                  rel="noreferrer"
                >
                  https://www.congress.gov/bill/114th-congress/senate-bill/1177
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default References;
